.WorkshopModal {
    &__content {
        .description {
            font-size: 1.2em;
            margin-top: 16px;
        }

        .speakers {
            display: flex;
            align-items: center;

            .avatars {
                margin-left: 10px;
            }
        }

        .tags {
            margin-top: 10px;

            &--tag {
                margin-right: 8px;
                border-radius: 20px;
                font-weight: normal;
            }
        }

        .footer {
            .controls {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .previous,
            .next {
                cursor: pointer;
                font-weight: bold;
                font-size: 1.1em;

                .ui.button {
                    color: white;
                }

                &.disabled {
                    cursor: not-allowed;
                }
            }

            .actions {
                .ui.icon.button.action {
                    padding-right: 2.5em !important;
                    padding-left: 1em !important;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .ui.modal {
        .WorkshopModal__content {
            &.content {
                padding: 0px !important;
            }

            .container {
                padding: 1rem !important;
            }

            .footer {
                .actions {
                    .ui.button.action {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}
