iframe.block--video__qa {
  border: 0;
}

.block--video--countdown {
  position: relative;

  &__container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .countdown__label {
      color: white;
      font-size: 20px;
      margin-bottom: 16px;
    }
  }
}

.VideoSizer {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;

  &--alwaysOpen {
    padding-bottom: initial;
    display: flex;
    flex-direction: row;

    .VideoSizer__container {
      position: relative;
      width: 100%;
    }

    .VideoSizer__tab {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;

      .block--video__qa {
        flex: 1;
      }
    }
  }

  &__container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    background: #fafafa;
  }

  &__video {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: relative;
    padding: 0;
    margin-right: 0;

    transition: margin-right 250ms, padding 250ms;

    // embed with bottom bar : .ui.embed { padding-bottom: calc(56.25% + 30px); }

    &--showTab {
      padding: 10px;
      margin-right: 250px;
    }

    .ui.embed {
      width: 100%;
    }
  }

  &__icon {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &__tab {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -250px;
    border-left: 1px solid #ccc;
    width: 250px;
    height: 100%;

    transition: right 250ms;

    &--showTab {
      right: 0;
    }

    .block--video__qa {
      width: 100%;
      height: 100%;
    }
  }
}
