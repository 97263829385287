.screen--type-workshop {
  >.ui.grid>.column>.ui.segments:not(:first-child) {
    margin: 2rem 0;
  }
}

.workshop {
  &--details {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    align-items: top;

    @media (min-width: 769px) and (max-width: 991px) {
      display: block;

      &__content {
        padding-top: 4px;
        padding-left: 0px !important;
      }
    }

    &__image {
      width: 350px;
      height: auto;
      border-radius: 8px;
      z-index: 1;
      box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    }

    &__content--category {
      margin-bottom: 8px;
    }
  }
}

@media (min-width: 769px) {
  .screen--type-workshop {
    .go-back {
      display: inline-block;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {

  .go-back {
    margin-top: 20px;
  }

  .workshops {
    &--container {
      .workshop {
        &--card3 {
          margin-right: 0px !important;
          width: 100% !important;
        }
      }
    }
  }

  .workshop {
    &--details {
      display: block;

      &__content {
        padding-top: 4px;
        padding-left: 0px !important;
      }
    }

    &--registrationBlock.ui.segment {
      position: fixed;
      bottom: 0;
      z-index: 10;
      left: 0;
      margin-bottom: 0px;
      border-radius: 0px;
      padding: 10px;
      right: 0px;
      width: 100%;
      text-align: center;
      border: none;

      .button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) and (orientation: landscape) {
  .workshop {
    &--registrationBlock.ui.segment {
      position: relative;
    }
  }
}